<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-button type="primary" @click="add" size="mini">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
       <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100" 
          label="序号"
        />
        <el-table-column prop="name" label="标签名称" min-width="220" />
        <el-table-column prop="createTime" label="创建时间" min-width="220" />
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button type="text"  icon="el-icon-edit" size="small" @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text"  icon="el-icon-delete" size="small" @click="onDel(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户标签名称:" prop="name">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入用户标签名称"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="vLoading" @click="submitForm('form')">提交</el-button>
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>
<script>
import {
  labelList,
  labelAdd,
  labelDelete,
  labelView,
  labelUpdate
} from "@/api/store/storeMember";
import {getIndex} from "@/utils/other";
export default {
  name: "banner",
  data() {
    return {
      vLoading:false,
      title: "添加用户标签",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        status: "1",
        storeId: "",
      },
      storeList: {},
      form: {
        name:'',
        code:'',
        associationCode:'',
        sort: "",
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        code: [{ required: true, message: "请输入", trigger: "blur" }],
        associationCode: [{ required: true, message: "请输入", trigger: "blur" }],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    openClick() {

      if (!this.form.storeId) {
        this.$message.error("请选择关联门店");
        return;
      }
      console.log("123",this.form.storeId);
      this.dialogVisibleLink = true;
    },
    selectChange() {
      console.log("1231");
      this.$forceUpdate();
    },
    getList() {
      this.listLoading = true;
      labelList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => { 
          this.listLoading = false;
        });
    },
    pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    storeChange(){
      this.form.aboutId = '';
      this.form.aboutName = '';
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.form.id=''
      this.title="新增用户标签"
      this.dialogVisible = true;
    },
    onDel(id){
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        labelDelete(id).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 编辑
    onEdit(row) {
      this.title="编辑用户标签"
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible =true
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (this.form.id) {
            this.vLoading = true
            labelUpdate({
              ...this.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              this.vLoading = false
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getList();
                this.handleClose();
                // done();
              } else {
                // done();
              }
            }).catch((err)=>{
              this.vLoading = false
            });
          }else{
            this.vLoading = true
            labelAdd({
              ...this.form,
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              this.vLoading = false
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getList();
                this.handleClose();
                // done();
              } else {
                // done();
              }
            }).catch((err)=>{
              this.vLoading = false
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  