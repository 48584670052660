import { get, put,Delete,post } from '../axios'

// 平台端-门店列表
export const clientUserAdd = params => post(`/user/web/clientUser/add`,params);

// // 平台端-门店管理-新增
// export const storeAdd = params => post(`/user/web/store/add`,params);
export const deptTree = params => get(`/user/web/store/deptTree`,params);
// 平台端-部门列表
export const storeDeptList = params => get(`/user/web/store/deptList`,params);
export const allIntegralAccountList = params => get(`/user/web/store/allIntegralAccountList`,params);
// // // 应用管理-删除
export const userDelete = params => Delete('/user/web/clientUser/delete/' + params, '');

// // 门店端-用户管理-冻结解冻
export const userStateUpdate = params => put(`/user/web/clientUser/state/update`,params);

// 门店端-用户管理-批量发放积分
export const sendIntegral = params => put(`/user/web/clientUser/sendIntegral`,params);
// 门店端-用户管理-修改个人积分
export const updateIntegral = params => put(`/user/web/clientUser/updateIntegral`,params);
// // 平台端-门店管理-详情
// export const storedetail = params => get('/user/web/store/detail/' + params, '');

// // 平台端-门店管理-是否启用-修改
// export const stateUpdate = params => put(`/user/web/store/state/update`,params);

// // 平台端-设置店铺积分
// export const updateIntegral = params => put(`web/store/updateIntegral`,params);

// 平台端-查看店铺积分
export const storeIntegral = params => get(`/user/web/store/integral/${params}`);

// 平台端-部门列表
export const labelList = params => get(`/user/web/clientUser/label/list`,params);

// 平台端-部门列表
export const labelAdd = params => post(`/user/web/clientUser/label/add`,params);

// 标签管理-删除
export const labelDelete = params => Delete(`/user/web/clientUser/label/delete/${params}`,params);

// 标签管理-详情
export const labelView = params => get(`/user/web/clientUser/label/view/${params}`);

//标签管理-编辑
export const labelUpdate = params => put(`/user/web/clientUser/label/update`,params);
//用户列表-部门修改
export const deptUpload = params => put(`/user/web/clientUser/user/dept/update`,params);

// 用户列表-标签列表
export const userLabelList = params => get(`/user/web/clientUser/user/label/list`,params);

// 用户列表-标签设置
export const labelUp = params => put(`/user/web/clientUser/user/label/update`,params);